import moment from 'moment';
import React, { useState, useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppStateAPI } from '../common/AppStateAPI';
import { PageLinks } from '../common/Constants';
import { APIData } from '../common/DataTypes';
import { ServerAPI } from '../common/ServerAPI';
import ImageMagnifier from '../elements/ImageMagnifier';


const IpvVerification: React.FC = () => {

    const location = useLocation();
    const videoRef: any = useRef(false);

    const user_id = location.state;
    const navigate = useNavigate()

    const [codeNumber, setCodeNumber] = useState<string>('');
    // const [videoPayer, setVideoPayer] = useState(true);
    const [IpvDatas, setIpvDatas] = useState<APIData.IpvDatas>()
    const [video_url, setVideoUrl] = useState("");
    const [aadhar_address, setAadhar_address] = useState<APIData.Digilocker_address_data>();
    const [pan_details, setPanDetails] = useState<APIData.AddressDatas_cvl_kra>();
    const [user_address, setUserAddress] = useState<APIData.UserAddress>();
    const [verify_status, setVerifyStatus] = useState("1");
    const { setShowPreloader } = useAppStateAPI();

    const [remarks, setRemarks] = useState("Lorem ipsum dolor sit amet consectetur adipisicing elit 1.");

    useEffect(() => {
        setShowPreloader(true);
        ServerAPI.getIPVDatas(user_id).then(async (res) => {
            setCodeNumber(res['random_code']);
            setIpvDatas(res);
            setAadhar_address(res['aadhar_address']);
            setUserAddress(res['user_address']);
            setPanDetails(res['pan_details']);
            setVideoUrl(res.in_person_video);

            console.log(res.in_person_verification_status);


            if (res.in_person_verification_status === 1) {
                setVerifyStatus("1")
            }
            if (res.in_person_verification_status === 2) {
                setVerifyStatus("2")
            } {

            }


        }).finally(() => {
            setShowPreloader(false)
        })

    }, [user_id, video_url]);



    const update_status = () => {
        ServerAPI.updateIPVStatus(verify_status, remarks, user_id).then((res) => {
            toast.success(res['message']);
            navigate(PageLinks.USERSDETAILS, { state: user_id })
        })

    }

    return (
        <div className='page-style'>
            <div className="step_box_content">
                <div className="col-xl-12">
                    <div className="row justify-content-center mx-5 my-5">
                        <div className="sub-bg">
                            <div className='col-lg-10 box-center'>
                                <div className=' row '>
                                    <div className="subscribe-wrapper">
                                        <div className="section-title section-title-3">
                                            <h6 className="mb-0 px-3 py-2 wow fadeInUp2 animated" data-wow-delay="0.1s">IPV Verification</h6>
                                            <Link to={PageLinks.USERSDETAILS} state={user_id} className="theme_btn profile_close_btn sub-btn wow fadeInUp2 animated" data-wow-delay="0.5s"><i className="far fa-times-circle"></i> Close</Link>
                                            <hr></hr>
                                            <form className="quote-form mb-10" action="#">
                                                <div className="row">
                                                    {/* <h6 className="pt-20 mb--10 fadeInUp2 animated" data-wow-delay="0.1s">Hey {localStorage.getItem('customerFirstName')+" "+localStorage.getItem('customerLastName')}, Let’s Verify Your Email ID</h6> */}
                                                    <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 pl-0 pt-20 float-left">
                                                        <div className="email-input input_field pl-0">
                                                            <label className="input-title">IPV Code</label>
                                                            <div className="partitioned_input_whole">
                                                                {codeNumber.split('').map((res: any, i: any) => {
                                                                    return (
                                                                        <div className="partitioned_input_box" key={i}>
                                                                            <div className="instruc_img_text"><b>{res}</b></div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                            {/* <div className="my-3">
                                                                <label className="input-title pb-3">PAN</label>
                                                                <ImageMagnifier
                                                                    src={'/assets/images/PAN_Card.jpg'}
                                                                    // src={IpvDatas?. ? "data:image/png;base64,"+IpvDatas.digilocker_image :"'/assets/images/aadhar.png'"}
                                                                />
                                                            </div> */}

                                                            <div className="my-3">
                                                                <label className="input-title pb-3">Aadhaar</label>
                                                                <ImageMagnifier
                                                                    src={IpvDatas?.digilocker_image ? "data:image/png;base64," + IpvDatas.digilocker_image : "'/assets/images/aadhar.png'"}
                                                                />
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-xl-7 col-lg-7 col-md-6 col-sm-12 pl-0 pt-20 float-left">
                                                        <div className="email-input input_field pl-0 mt-3 mb-3">
                                                            <div className='my-3 '>
                                                                <video height={250} autoPlay playsInline controls={true} controlsList="nodownload noplaybackrate" src={IpvDatas?.in_person_video}>
                                                                    <source type="video/mp4 " />
                                                                </video>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='my-4 col-lg-12 col-md-12 col-sm-12 card-padding'>
                                                        <div className="card ">
                                                            <div className="card-header">
                                                                <h5 className="m-0 header"> Details of you given </h5>

                                                            </div>
                                                            <div className="card-body ipv-card-body ">
                                                                <div className="row">
                                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-sm-3 col-lg-3 ipv-card-items">
                                                                        <p className="detail-title ipv-card-text">User Name</p>
                                                                        {IpvDatas?.user_name === "" || IpvDatas?.user_name === null ? <h6> N/A </h6> : <h6>{IpvDatas?.user_name}</h6>}
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-sm-3 col-lg-3 ipv-card-items">
                                                                        <p className="detail-title ipv-card-text">Pan Name</p>
                                                                        {!pan_details?.cvl_kra_pan_name && <h6>N/A</h6>}
                                                                        {pan_details?.cvl_kra_pan_name === "" || pan_details?.cvl_kra_pan_name === null ? <h6> N/A </h6> : <h6>{pan_details?.cvl_kra_pan_name}</h6>}
                                                                    </div>

                                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-sm-3 col-lg-3 ipv-card-items">
                                                                        <p className="detail-title ipv-card-text">Aadhaar Name</p>
                                                                        {IpvDatas?.aadhar_name === "" || IpvDatas?.aadhar_name === null ? <h6> N/A </h6> : <h6>{IpvDatas?.aadhar_name}</h6>}
                                                                    </div>

                                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-sm-3 col-lg-3 ipv-card-items">
                                                                        {IpvDatas?.name_match === 1 ? <a className="matched mx-2"><b>MATCHED</b></a> : <a className="not-match mx-2"><b>NOT MATCHED</b></a>}
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-sm-3 col-lg-3 ipv-card-items">
                                                                        <p className="detail-title ipv-card-text">User Date Of Birth</p>
                                                                        {IpvDatas?.user_dob === "" || IpvDatas?.user_dob === null ? <h6> N/A </h6> : <h6>{moment(IpvDatas?.user_dob).format('DD-MM-YYYY')}</h6>}
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-sm-3 col-lg-3 ipv-card-items">
                                                                        <p className="detail-title ipv-card-text">Pan Date Of Birth</p>
                                                                        {!pan_details?.cvl_kra_dob && <h6>N/A</h6>}
                                                                        {pan_details?.cvl_kra_dob === "" || pan_details?.cvl_kra_dob === null ? <h6> N/A </h6> : <h6>{pan_details?.cvl_kra_dob}</h6>}
                                                                    </div>

                                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-sm-3 col-lg-3 ipv-card-items">
                                                                        <p className="detail-title ipv-card-text">Aadhaar Date Of Birth</p>
                                                                        {IpvDatas?.aadhar_dob === "" || IpvDatas?.aadhar_dob === null ? <h6> N/A </h6> : <h6>{moment(IpvDatas?.aadhar_dob).format('DD-MM-YYYY')}</h6>}
                                                                    </div>

                                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-sm-3 col-lg-3 ipv-card-items">
                                                                        {IpvDatas?.date_of_birth_match === 1 ? <a className="matched mx-2"><b>MATCHED</b></a> : <a className="not-match mx-2"><b>NOT MATCHED</b></a>}
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-sm-3 col-lg-3 ipv-card-items">
                                                                        <p className="detail-title ipv-card-text">User Address</p>
                                                                        {user_address?.address_line_1 === "" || user_address?.address_line_1 === null ? <h6> N/A </h6> : <h6>{user_address?.address_line_1},{user_address?.address_line_2},{user_address?.address_city},{user_address?.address_state},{user_address?.address_zip}</h6>}
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-sm-3 col-lg-3 ipv-card-items">
                                                                        <p className="detail-title ipv-card-text">Pan Address</p>
                                                                        {pan_details?.cvl_kra_address_line_1 === "" || pan_details?.cvl_kra_address_line_1 === null ? <h6> N/A </h6> : <h6>{pan_details?.cvl_kra_address_line_1},{pan_details?.cvl_kra_address_line_2},{pan_details?.cvl_kra_city},{pan_details?.cvl_kra_state},{pan_details?.cvl_kra_business_pincode}</h6>}
                                                                    </div>


                                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-sm-3 col-lg-3 ipv-card-items">
                                                                        <p className="detail-title ipv-card-text">Aadhaar Address</p>
                                                                        {aadhar_address?.address_line_1 === "" || aadhar_address?.address_line_1 === null ? <h6> N/A </h6> : <h6>{aadhar_address?.house_no},{aadhar_address?.street},{aadhar_address?.landmark},{aadhar_address?.village_town_city},{aadhar_address?.district},{aadhar_address?.state},{aadhar_address?.pincode}</h6>}
                                                                    </div>

                                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-sm-3 col-lg-3 ipv-card-items">
                                                                        {IpvDatas?.address_match === 1 ? <a className="matched mx-2"><b>MATCHED</b></a> : <a className="not-match mx-2"><b>NOT MATCHED</b></a>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-xl-7 col-lg-7 col-md-6 col-sm-12 pl-0 pt-20 float-left'>
                                                        <div className='col-lg-4  email-input input_field pl-0'>
                                                            <label className="input-title pb-3">Verification Status</label>
                                                            <select className='select-style' onChange={(e) => setVerifyStatus(e.target.value)} name="verification_status">
                                                                <option value={1} selected={IpvDatas?.in_person_verification_status == "1" ? true : false} >Verified</option>
                                                                <option value={2} selected={IpvDatas?.in_person_verification_status == "2" ? true : false}>Rejected</option>
                                                            </select>
                                                        </div>
                                                        <div className=' email-input input_field pl-0'>
                                                            <label className="input-title pb-3">Remarks</label>
                                                            <select className='select-style' placeholder='Select From drop down' name="remarks" onChange={(e) => setRemarks(e.target.value)}>
                                                                <option disabled> Select From drop down</option>
                                                                <option value="Lorem ipsum dolor sit amet consectetur adipisicing elit 1." selected={IpvDatas?.in_person_verification_remarks == "Lorem ipsum dolor sit amet consectetur adipisicing elit 1." ? true : false} > Lorem ipsum dolor sit amet consectetur adipisicing elit 1.</option>
                                                                <option value="Lorem ipsum dolor sit amet consectetur adipisicing elit 2." selected={IpvDatas?.in_person_verification_remarks == "Lorem ipsum dolor sit amet consectetur adipisicing elit 2." ? true : false}>Lorem ipsum dolor sit amet consectetur adipisicing elit 2.</option>
                                                                <option value="Lorem ipsum dolor sit amet consectetur adipisicing elit 1." selected={IpvDatas?.in_person_verification_remarks == "Lorem ipsum dolor sit amet consectetur adipisicing elit 3." ? true : false}>Lorem ipsum dolor sit amet consectetur adipisicing elit 3.</option>

                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='row text-end my-3 py-5 '>
                                                        <div className=''>
                                                            <Link to={PageLinks.USERSDETAILS} state={user_id} className='cancel-btn mx-3' >Cancel</Link>
                                                            {/* </div>
                                                        <div className='col-lg-6'> */}
                                                            <a className='save-btn' onClick={() => update_status()}>Save</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default IpvVerification;
