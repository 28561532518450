import { Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { PageLinks } from '../common/Constants';
import ConfirmationModal from './Confirmation-modal';

interface modalDatas {
    openModal?: any,
    handleClose?: any,
    handleOpen?: any,
    userId?: any,
    // currentPage?:any
    // handleOpen
}

const ImagePreviewModal: React.FC<modalDatas> = ({ handleOpen, userId, openModal, handleClose }) => {

    const [open, setOpen] = useState(false);
    const [open_modal, setOpenModal] = useState(false);
    const [all_datas, setAllDatas] = useState({
        // userId:userId,
        user_status: 2,
        account_status_reason: "Lorem ipsum dolor sit amet consectetur adipisicing elit 1.",
        account_status_remarks: ""
    })
    // const [select_status, setSelectStatus] = useState<any>(1)

    useEffect(() => {
        if (openModal === true) {
            setOpen(true);
        } else {
            setOpen(false);
        }

    }, [openModal])

    const handleCloses = () => {
        setOpen(false);
        // setOpenModal(false)
        handleClose(false);
    };
    const handleClosed = (e: any) => {
        setOpenModal(e)
        setOpen(e);
        handleClose(e);

    }

    const update_status = (e: any) => {

        setAllDatas({
            ...all_datas,
            [e.target.name]: e.target.value
        })
    }

    const save_modal = () => {
        setOpen(false);
        setOpenModal(true)
        handleClose(false)
    }

    return (
        <div className='dialog'>
            <Dialog
                open={open}
                className="dialog-box"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" className="model-title" style={{ width: "100%" }}>
                    <div className="modal-box-title">
                        <p className="m-0 quicksand-medium">Image Preview</p>
                        <p className="m-0"><img src='../../assets/images/download.svg' style={{ width: "10px", cursor: "pointer", margin: "0px 5px" }} /></p>
                        <p className="m-0"><img src='../../assets/images/print.svg' style={{ width: "10px", cursor: "pointer", margin: "0px 5px" }} /></p>
                        <p className="m-0" onClick={handleCloses}><i className="fa fa-times cursor-pointer font-style" aria-hidden="true"></i></p>
                    </div>
                </DialogTitle>
                <hr />

                <DialogContent>
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                        <img src='../../assets/images/default.svg' />
                    </div>
                </DialogContent>
            </Dialog>
            <ConfirmationModal contents="Are you sure you want to confirm this?" details_datas={all_datas} currentPage="update-status" openModal={open_modal} handleClose={(e: any) => handleClosed(e)} user_id={userId} />

        </div>
    );
}

export default ImagePreviewModal;